import { FloatingPortal } from "@floating-ui/react";
import { faXmark, faCheck, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useRouteLoaderData, useSearchParams } from "@remix-run/react";
import classNames from "classnames";
import type { Dispatch, SetStateAction } from "react";
import { useCallback, useEffect, useRef, useState, memo } from "react";
import { toast } from "react-toastify";
import useOnClickOutside from "~/hooks/useClickOutside";
import { fetchAccountSubscriptions } from "~/utils/hive";
import { FloatingHTMLOverlay } from "../FloatingHTMLOverlay";
import { useAppStore } from "~/store";
import { useCommunitiesFetcher } from "~/hooks/contentHooks";
import { useDebounce } from "~/hooks/useDebounce";
import { loadTranslator } from "~/utils/loadTranslator";

function PublishChooseCommunities({
  visibility,
  tags,
  setVisibility,
  setTags
}: {
  visibility: boolean;
  tags: any[];
  setVisibility: Dispatch<SetStateAction<boolean>>;
  setTags: (tags: any[]) => Dispatch<SetStateAction<any[]>>;
}) {
  const t = loadTranslator(useRouteLoaderData("root").translations)

  const [searchParams] = useSearchParams();

  const [search, setSearch] = useState("");

  const activeAccount = useAppStore(store => store.account.activeAccount);

  const isDarkMode = useAppStore(store => store.settings.dark);

  const communityModalRef = useRef<HTMLDivElement>(null);

  const [communities, setCommunities] = useState<Array<any> | null>([]);

  const createOption = (label: string, value: string) => ({
    label,
    value
  });

  const debouncedSearch = useDebounce(search, 750);

  const [fetchedContent] = useCommunitiesFetcher(
    {
      query: debouncedSearch,
      sort: "rank",
      limit: 20,
      last: "",
      observer: activeAccount?.name || ""
    },
    false,
    true
  );
  const parseFetchedContent = () => {
    return (fetchedContent as never[]).map(x => [x.name, x.title, x.context.role, ""]);
  };

  useEffect(() => {
    if (!activeAccount || !visibility) return;

    void (async function getCommunities() {
      try {
        const response = await fetchAccountSubscriptions(activeAccount?.name);
        if (!response?.length) {
          setCommunities(null);
          return;
        }
        setCommunities(response);
      } catch (error) {
        console.log("Error fetching communities", error);
      }
    })();
  }, [activeAccount, visibility]);

  const addCommunity = useCallback(
    (community: [string, string, string, string]) => {
      // if limit is exceeded and there is no community selected

      if (tags?.length === 10 && !tags?.filter(t => t?.value?.startsWith("hive-"))?.length) {
        return toast("You have reached the maximum number of tags. Please delete one of the other tags to add more.", {
          type: "error",
          theme: isDarkMode ? "dark" : "light",
          autoClose: 3000
        });
      }

      // if any community tag is already added, remove it
      if (tags?.filter(t => t?.value?.startsWith("hive-"))?.length > 0) {
        const newTags = tags.filter(t => !t?.value?.startsWith("hive-"));
        newTags.unshift(createOption(community[1], community[0]));

        setTags(newTags);
        return setTimeout(() => setVisibility(false), 250);
      }

      setTags(prev => [createOption(community[1], community[0]), ...prev]);
      setTimeout(() => setVisibility(false), 250);
    },
    [tags, setTags, isDarkMode]
  );

  const removeCommunityTags = useCallback(() => {
    // if limit is exceeded and there is no community selected
    if (!tags.filter(t => t?.value?.startsWith("hive-")).length) return;

    setTags(prev => prev.filter(t => !t?.value?.startsWith("hive-")));
    setTimeout(() => setVisibility(false), 250);
  }, [tags, setTags]);

  useOnClickOutside(communityModalRef, () => setVisibility(false));

  useEffect(() => {
    const community = searchParams.get("community");
    if (community && communities !== null) {
      const selected = communities.find((item: Array<string>) => {
        return item[0] === community;
      });
      if (selected) {
        addCommunity(selected);
      }
    }
  }, [searchParams, communities]);

  return (
    <FloatingPortal>
      {visibility && (
        <FloatingHTMLOverlay
          onClick={ev => ev.stopPropagation()}
          className="bg-overlay z-[1000] flex justify-center py-[10vh]"
        >
          <div
            ref={communityModalRef}
            className="relative py-4 px-6 flex flex-col gap-y-6 rounded-xl border-xl bg-pri dark:bg-pri-d border-pri dark:border-pri w-10/12 h-fit pc:w-4/12 pc:max-h-fit md:w-6/12 sm:w-8/12 xs:w-full sm:min-w-[480px]"
          >
            <header className="flex items-center justify-between pb-4 border-b border-pri dark:border-zinc-700/[.75]">
              <strong className="text-lg">{t("choose-communities")}</strong>
              <div
                onClick={() => setVisibility(false)}
                className="absolute p-2 h-8 w-8 right-4 flex items-center justify-center rounded-full hover:bg-neutral-200 dark:hover:bg-neutral-700 transition-colors cursor-pointer"
              >
                <FontAwesomeIcon icon={faXmark} />
              </div>
            </header>

            <div className="flex flex-col gap-y-3">
              <div className="relative flex flex-row items-center gap-1">
                <input
                  type="text"
                  className="peer w-64 h-10 px-4 pl-11 text-sm font-medium placeholder:text-gray-500 text-pri dark:text-pri-d rounded-3xl outline-none bg-gray-200 dark:bg-zinc-800 focus:bg-gray-300 dark:focus:bg-zinc-700 transition-colors duration-150"
                  placeholder="Search..."
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                />

                <span className="absolute left-4">
                  <FontAwesomeIcon icon={faSearch} className="text-gray-500" />
                </span>
              </div>
              {communities === null ? (
                <div>There is no communities you subscribed.</div>
              ) : communities?.length === 0 ? (
                <div>Loading...</div>
              ) : (
                <div className="flex flex-row flex-wrap flex-1 gap-4">
                  {communities &&
                    (search === "" ? communities : parseFetchedContent(fetchedContent))?.map(
                      c =>
                        c[0] !== undefined && (
                          <button
                            key={c[0]}
                            className={classNames(
                              "group flex flex-1 w-full min-w-[200px] items-center gap-x-5 p-2.5 rounded-lg bg-gray-100 dark:bg-zinc-800 cursor-pointer",
                              {
                                "bg-acc text-pri": !!tags?.find(t => t?.value === c[0])
                              }
                            )}
                            onClick={() => addCommunity(c)}
                          >
                            <label
                              htmlFor={`community-${c[0]}`}
                              className="flex flex-1 items-center justify-between pointer-events-none"
                            >
                              <div className="relative flex w-6 h-6 rounded-full overflow-hidden">
                                <span
                                  className="flex flex-1 bg-no-repeat bg-center bg-cover w-full h-full bg-gray-200 dark:bg-zinc-700"
                                  style={{
                                    backgroundImage: `url(https://img.inleo.io/u/${c[0]}/avatar/small)`
                                  }}
                                ></span>
                              </div>

                              <div className="flex flex-1 items-center justify-between gap-x-4">
                                <span className="line-clamp-1 ml-2 text-start text-sm font-semibold text-gray-900 dark:text-gray-300">
                                  {c[1] || c[0]}
                                </span>
                                <input
                                  type="radio"
                                  id={`community-${c[0]}`}
                                  name="community"
                                  defaultChecked={!!tags?.find(t => t?.value === c[0])}
                                  className="hidden w-0 h-0 opacity-0 visibility-none"
                                />
                                <span className="flex items-center justify-center w-5 h-5 border-2 border-gray-300 dark:border-pri-d rounded-full bg-transparent group-hover:bg-pri-d/[.05] dark:group-hover:bg-pri/[.05] transition-colors">
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    className={classNames("text-acc opacity-0 transition-opacity text-sm", {
                                      "opacity-100": !!tags?.find(t => t?.value === c[0])
                                    })}
                                  />
                                </span>
                              </div>
                            </label>
                          </button>
                        )
                    )}

                  <button
                    className={classNames(
                      "group flex flex-1 max-w-[calc(50%-10px)] min-w-[150px] items-center gap-x-5 p-2.5 pl-3 rounded-lg bg-gray-100 dark:bg-zinc-800 cursor-pointer",
                      {
                        "bg-acc text-pri": !tags?.find(t => t?.value?.startsWith("hive-"))
                      }
                    )}
                    onClick={removeCommunityTags}
                  >
                    <label
                      htmlFor={`community-my-blog`}
                      className="flex flex-1 items-center justify-between pointer-events-none"
                    >
                      <span className="ml-2 text-sm font-semibold text-gray-900 dark:text-gray-300">My Blog</span>
                      <input
                        type="radio"
                        id={`community-my-blog`}
                        name="community"
                        defaultChecked={!tags?.find(t => t?.value?.startsWith("hive-"))}
                        className="hidden w-0 h-0 opacity-0 visibility-none"
                      />
                      <span className="flex items-center justify-center w-5 h-5 border-2 border-gray-300 dark:border-pri-d rounded-full bg-transparent group-hover:bg-pri-d/[.05] dark:group-hover:bg-pri/[.05] transition-colors">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className={classNames("text-acc opacity-0 transition-opacity text-sm", {
                            "opacity-100": !tags?.find(t => t?.value?.startsWith("hive-"))
                          })}
                        />
                      </span>
                    </label>
                  </button>
                </div>
              )}

              <div className="flex flex-col items-center gap-y-2 pt-3">
                <small className="text-center text-gray-500 dark:text-zinc-400">
                  {t("explore-and-join-communities")}
                </small>
                <Link to="/communities" target="_blank" rel="norefeerer nooppener" className="flex flex-1 w-full">
                  <button
                    type="button"
                    className="flex flex-1 justify-center items-center text-center w-full py-2.5 px-5 rounded-lg bg-acc text-pri font-semibold text-sm hover:opacity-80 transition-opacity duration-150"
                  >
                    {t("explore-communities")}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </FloatingHTMLOverlay>
      )}
    </FloatingPortal>
  );
}

export default memo(PublishChooseCommunities);

