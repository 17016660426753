import {
  useFloating,
  flip,
  hide,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal
} from "@floating-ui/react";
import {
  faCopy,
  faArrowDown,
  faRepeat,
  faFileLines,
  faEdit,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo } from "react";
import { toast } from "react-toastify";
import { useAppStore } from "~/store";
import { deleteComment } from "~/utils/transactions";
import { PremiumOnlyBadge } from "../Premium";
import { copyWithRef } from "~/utils/ref";
import { cn } from "~/utils/cn";
import { useRouteLoaderData } from "@remix-run/react";
import { loadTranslator } from "~/utils/loadTranslator";

const ControlledThreadOptions = memo(
  ({
    threadContent,
    open,
    setOpen,
    cursor,
    setDownVote,
    setEditThread,
    setRethread
  }: FullThreadProps & {
    open: boolean;
    setOpen: (open: boolean) => void;
    cursor: { x: number; y: number };
    onDownVoteCallback?: () => void;
  }) => {
    const t = loadTranslator(useRouteLoaderData("root").translations)
    const [activeAccount, premiumState, isDarkMode, setLists] = useAppStore(
      store => [
        store.account.activeAccount,
        store.account.premium,
        store.settings.dark,
        store.utilities.setLists
      ]
    );

    const encrypted = threadContent.json_metadata?.encrypted;
    const handleDropdownItemAction = (
      event: React.MouseEvent,
      action: string
    ) => {
      event.preventDefault();
      event.stopPropagation();

      if (action === "newtab") {
        window.open(
          `https://inleo.io/threads/view/${threadContent?.author}/${threadContent?.permlink}`,
          "_blank"
        );
      }

      if (action === "copylink") {
        copyWithRef(
          `https://inleo.io/threads/view/${threadContent?.author}/${threadContent?.permlink}`,
          threadContent?.author
        );
        toast("Copied thread link to clipboard", {
          type: "success",
          theme: isDarkMode ? "dark" : "light",
          autoClose: 3_000
        });
      }
      if (action === "tweet") {
        if (encrypted) return;

        const bodyForTwitter = threadContent?.body
          .replaceAll("#", "")
          .replaceAll("@", "");
        window.open(
          `https://x.com/intent/post?text=${bodyForTwitter}\n&url=https://inleo.io/threads/view/${threadContent.author}/${threadContent.permlink}`
        );
      }

      if (action === "list") {
        setLists({ modal: true, author: threadContent.author });
      }

      if (action === "downvote") {
        setDownVote(true);
      }
      if (action === "rethread") {
        if (encrypted) return;
        setRethread(true);
      }
      if (action === "edit") {
        if (!premiumState?.is_premium) {
          return toast("You need to be premium user to use this feature.", {
            type: "warning",
            theme: isDarkMode ? "dark" : "light",
            autoClose: 3_000
          });
        }
        setEditThread(true);
      }
      if (action === "delete") {
        deleteComment({
          author: threadContent?.author || "",
          permlink: threadContent?.permlink || ""
        })
          .then(() => {
            toast(
              `Your thread ${threadContent?.permlink} is successfully deleted.`,
              {
                type: "success",
                theme: isDarkMode ? "dark" : "light",
                autoClose: 3_000
              }
            );
          })
          .catch(() => {
            toast(`Failed to delete thread ${threadContent?.permlink}.`, {
              type: "error",
              theme: isDarkMode ? "dark" : "light",
              autoClose: 3_000
            });
          });
      }

      setOpen(false);
    };

    const { reference, floating, context } = useFloating({
      open: open,
      onOpenChange: setOpen,
      middleware: [flip(), hide()]
    });

    const dismiss = useDismiss(context, {
      ancestorScroll: true
    });

    const role = useRole(context, { role: "menu" });

    const { getReferenceProps, getFloatingProps } = useInteractions([
      role,
      dismiss
    ]);

    return (
      <div ref={reference} {...getReferenceProps()} className="z-[101]">
        {open && (
          <FloatingPortal>
            <div
              ref={floating}
              className="fixed w-max min-w-[180px] right-0 mt-2 p-1 flex flex-col rounded-lg bg-pri dark:bg-pri-d shadow-[0_0_8px_rgb(255_255_255_/_17%)] border border-pri dark:border-pri-d z-[10001]"
              style={{ top: cursor.y, left: cursor.x }}
              // {...getReferenceProps()}
              {...getFloatingProps()}
            >
              {/* public actions */}
              <div
                className="flex items-center w-full p-3 font-semibold text-sm text-gray-900 dark:text-gray-300 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer"
                onClick={event => handleDropdownItemAction(event, "copylink")}
              >
                <span className="flex w-6 mr-2">
                  <FontAwesomeIcon icon={faCopy} />
                </span>
                {t("copy-link")}
              </div>

              <div
                className="flex items-center w-full p-3 font-semibold text-sm text-gray-900 dark:text-gray-300 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer"
                onClick={event => handleDropdownItemAction(event, "newtab")}
              >
                <span className="flex w-6 mr-2">
                  <FontAwesomeIcon icon={faCopy} />
                </span>
                {t("open-in-new-tab")}
              </div>

              <div
                className="flex items-center w-full p-3 font-semibold text-sm text-gray-900 dark:text-gray-300 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer"
                onClick={event => handleDropdownItemAction(event, "downvote")}
              >
                <span className="flex w-6 mr-2">
                  <FontAwesomeIcon icon={faArrowDown} />
                </span>
                {t("downvote")}
              </div>

              <div
                className={cn(
                  "flex items-center w-full p-3 font-semibold text-sm text-gray-900 dark:text-gray-300 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer",
                  {
                    "opacity-50 cursor-not-allowed": encrypted
                  }
                )}
                onClick={event => handleDropdownItemAction(event, "rethread")}
              >
                <span className="flex w-6 mr-2">
                  <FontAwesomeIcon icon={faRepeat} />
                </span>
                {t("re-thread")}
              </div>

              <div
                className={cn(
                  "flex items-center w-full p-3 font-semibold text-sm text-gray-900 dark:text-gray-300 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer",
                  {
                    "opacity-50 cursor-not-allowed": encrypted
                  }
                )}
                onClick={event => handleDropdownItemAction(event, "tweet")}
              >
                <span className="flex w-6 mr-2">
                  <svg
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    fill="currentColor"
                    className="w-[14px] h-[14px]"
                  >
                    <g>
                      <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
                    </g>
                  </svg>
                </span>
                Tweet
              </div>

              {/* premium actions */}
              {premiumState.is_premium && (
                <div
                  className="flex items-center w-full p-3 font-semibold text-sm text-gray-900 dark:text-gray-300 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer"
                  onClick={event => handleDropdownItemAction(event, "list")}
                >
                  <span className="flex w-6 mr-2">
                    <FontAwesomeIcon icon={faFileLines} />
                  </span>
                  {t("add-to-lists")}
                </div>
              )}

              {/* private actions (owner) */}
              {threadContent.author === activeAccount?.name && (
                <React.Fragment>
                  <hr className="bg-pri-d dark:bg-pri my-2 opacity-10" />

                  <div
                    className="flex items-center w-full p-3 font-semibold text-sm text-gray-900 dark:text-gray-300 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer"
                    onClick={event => handleDropdownItemAction(event, "edit")}
                  >
                    <span className="flex w-6 mr-2">
                      <FontAwesomeIcon icon={faEdit} />
                    </span>
                    {t("edit")}
                    <PremiumOnlyBadge className="ml-auto" />
                  </div>

                  <div
                    className="flex items-center w-full p-3 font-semibold text-sm text-red-500 dark:text-red-500 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer"
                    onClick={event => handleDropdownItemAction(event, "delete")}
                  >
                    <span className="flex w-6 mr-2">
                      <FontAwesomeIcon icon={faTrash} />
                    </span>
                    {t("delete")}
                  </div>
                </React.Fragment>
              )}
            </div>
          </FloatingPortal>
        )}
      </div>
    );
  }
);

ControlledThreadOptions.displayName = "ControlledThreadOptions";
export default ControlledThreadOptions;
