import React, { forwardRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { cn } from "~/utils/cn";
import { useRouteLoaderData } from "@remix-run/react";
import { loadTranslator } from "~/utils/loadTranslator";

interface SubmitButtonProps {
  handleClick: any;
  posted: any;
  disabled: any;
  type: any;
  loading: boolean;
  encrypted?: boolean;
  MarkdownEditorTypes: any;
}

const SubmitButton = forwardRef(
  (
    { handleClick, posted, encrypted, disabled, type, loading, MarkdownEditorTypes }: SubmitButtonProps,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    const t = loadTranslator(useRouteLoaderData("root").translations)
  
    return (
      <button
        ref={ref}
        onClick={handleClick}
        about={"CTRL + ENTER"}
        title={"CTRL + ENTER"}
        className={cn(
          "flex items-center justify-center min-w-[77px] min-h-[32px] sm:min-h-[35px] sm:min-w-[88px] px-5 py-2 gap-x-1.5 text-sm font-semibold text-pri rounded-3xl bg-acc hover:bg-acc-hov disabled:cursor-not-allowed disabled:opacity-75 transition-all duration-300",
          {
            "bg-green-600 disabled:bg-green-500 disabled:opacity-100 duration-30": posted,
            "bg-c-subscriptions hover:bg-c-subscriptions/90": encrypted && !posted
          }
        )}
        disabled={disabled}
      >
        {encrypted && !posted ? <FontAwesomeIcon icon={faCrown} size="sm" className="-mt-px" /> : null}
        {posted ? (
          t("onchain")
        ) : loading ? (
          <FontAwesomeIcon icon={faSpinner} size="sm" className="animate-spin" />
        ) : (
          t(MarkdownEditorTypes[type])
        )}
      </button>
    );
  }
);

SubmitButton.displayName = "SubmitButton";
export default SubmitButton;

