import * as React from "react";
import { useEffect, useState } from "react";
import type { BaseEditor } from "slate";
import { createEditor, Editor, Node, Range, Transforms } from "slate";
import { uploadImage } from "~/utils/images";
import {
  FloatingPortal,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole
} from "@floating-ui/react";
import { FloatingHTMLOverlay } from "~/components/FloatingHTMLOverlay";
import { ReactEditor, Slate, Editable, withReact } from "slate-react";
import type { HistoryEditor } from "slate-history";
import { withHistory } from "slate-history";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faCrown, faLockOpen, faX } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { isPostLink, isThreadLink } from "~/utils/markdown";
// @ts-ignore
import { ClientOnly } from "remix-utils/client-only";
import fileUploadLogo from "~/images/photo.png";
import { queryAccountLookup } from "~/utils/hive";
import useOnClickOutside from "~/hooks/useClickOutside";
import { SmallAvatar12, SmallAvatar8 } from "./format/SmallAvatar";
import { useAppStore } from "~/store";
import type { PollOptions } from "./format/Polls";
import { PollPortal } from "./format/Polls";
import { cache } from "~/utils/cache";
import ImageSelector from "./publish/PublishEditor/ImageSelector";
import { isSSR } from "~/utils/ssr";
import ProfileImage from "~/components/ProfileImage";
import type { CustomText, MarkdownEditorProps, MarkdownEditorState } from "~/components/MarkdownEditor/types";
import { initialMarkdownEditorState, initialValue } from "~/components/MarkdownEditor/consts";
import { getRandomPlaceholder } from "~/components/MarkdownEditor/utils";
import { EditorFooter } from "~/components/MarkdownEditor/EditorFooter";
import WarningMessage from "~/components/MarkdownEditor/WarningMessage";
import FileUploadSpinLoader from "~/components/MarkdownEditor/FileUploadSpinLoader";
import { AnimatePresence, motion } from "framer-motion";
import { cn } from "~/utils/cn";
import { useRouteLoaderData } from "@remix-run/react";
import { loadTranslator } from "~/utils/loadTranslator";

export enum MarkdownEditorTypes {
  Post,
  Thread,
  Comment
}

export const MarkdownEditor = ({
  handlePost,
  type,
  mainThreadEditor = false,
  initialContent,
  autoFocus,
  encryption,
  setEncryption,
  className
}: MarkdownEditorProps) => {
  const imageInputRef = React.useRef(null);

  const activeAccount = useAppStore(store => store.account.activeAccount);
  const premiumState = useAppStore(store => store.account.premium);
  const account = useAppStore(store => store.account);

  const [state, setState] = useState<MarkdownEditorState>({
    ...initialMarkdownEditorState,
    placeholder: getRandomPlaceholder(type)
  });
  const {
    togglePreview,
    editorMounted,
    imageUrl,
    dragActive,
    fileDropped,
    fileUploading,
    open,
    showInfo,
    containsLink,
    pollsOpen,
    pollOptions,
    pollCreated,
    placeholder,
    mentionSuggestionActive,
    mentionWritten,
    accountLookupResults,
    caretCoordinates
  } = state;
  const threadSubmitRef = React.useRef<HTMLButtonElement>(null);
  const [editor] = useState(() => withReact(withHistory(createEditor())));
  const setTogglePreview = (value: boolean) => {
    setState(prev => ({ ...prev, togglePreview: value }));
  };

  const setPollsOpen: React.Dispatch<React.SetStateAction<boolean>> = (value: boolean) =>
    setState(prev => ({ ...prev, pollsOpen: value }));

  const setPollOptions: React.Dispatch<React.SetStateAction<PollOptions>> = (value: PollOptions) =>
    setState(prev => ({ ...prev, pollOptions: value }));
  const setPollCreated: React.Dispatch<React.SetStateAction<boolean>> = (value: boolean) =>
    setState(prev => ({ ...prev, pollCreated: value }));

  const setOpen = (value: boolean) => {
    setState(prev => ({ ...prev, open: value }));
  };

  const setImageUrl = (value: boolean | string) => {
    setState(prev => ({ ...prev, imageUrl: value }));
  };

  const mentionViewer = React.useRef(null);

  useOnClickOutside(mentionViewer, () => onClose());

  const enforceSelection = () => {
    if (editor.selection === null) {
      const [last] = Editor.positions(editor, {
        unit: "offset",
        reverse: true
      });
      const empty = { path: [0, 0], offset: 0 };
      Transforms.select(editor, {
        anchor: last ?? empty,
        focus: last ?? empty
      });
      ReactEditor.focus(editor);
    }
  };

  const wrapWithDelim = (ev: React.MouseEvent, name: string, delimiter: string) => {
    ev.preventDefault();
    enforceSelection();

    if (Range.isCollapsed(editor?.selection!)) {
      // f;

      Transforms.insertText(editor, `${delimiter}${name}${delimiter}`);

      const { path, offset } = Range.end(editor.selection!);

      const focus = { path, offset: offset - delimiter?.length };
      const anchor = { path, offset: focus.offset - name?.length };

      Transforms.select(editor, { anchor, focus });

      return;
    }

    const ref = Editor.rangeRef(editor, editor.selection!, {
      affinity: "inward"
    });

    Transforms.insertText(editor, delimiter, {
      at: Range.start(ref?.current!)
    });
    Transforms.insertText(editor, delimiter, {
      at: Range.end(ref?.current!)
    });
    Transforms.select(editor, ref?.current!);

    ref.unref();
  };

  const startWithDelim = (ev: React.MouseEvent, name: string, delimiter: string, nextLine: boolean) => {
    ev.preventDefault();

    enforceSelection();

    const emptyText = `${delimiter} ${name}`;
    const newLine = nextLine ? "\n" : "";

    if (Range.isCollapsed(editor.selection!)) {
      const path = editor.selection!.focus.path;
      const parent = Node.parent(editor, path);
      const length = parent?.children?.length! ?? 0;

      if (length === 0 || (length === 1 && (parent.children[0] as CustomText).text.length === 0)) {
        Transforms.removeNodes(editor);
        if (path[0] === 0) {
          Transforms.insertNodes(editor, [{ type: "paragraph", children: [{ text: emptyText }] }]);
        } else {
          Transforms.insertNodes(editor, [
            {
              type: "paragraph",
              children: [{ text: `${newLine}${emptyText}` }]
            }
          ]);
        }
      } else {
        Transforms.insertNodes(editor, [
          {
            type: "paragraph",
            children: [{ text: `${newLine}${emptyText}` }]
          }
        ]);
      }
    } else {
      const ref = Editor.rangeRef(editor, editor?.selection!, {
        affinity: "backward"
      });
      const selectRef = Editor.rangeRef(editor, editor?.selection!, {
        affinity: "inward"
      });

      Transforms.insertText(editor, `${newLine}${delimiter} `, {
        at: Range.start(ref.current!)
      });
      Transforms.wrapNodes(editor, { type: "paragraph", children: [] }, { at: ref.current!, split: true });

      const next = Editor.after(editor, ref.current!, { unit: "block" });
      Transforms.unwrapNodes(editor, { at: next });
      Transforms.select(editor, selectRef.current!);

      selectRef.unref();
      ref.unref();
    }
  };

  const insertImage = (imageUrl: String | boolean) => {
    enforceSelection();

    Transforms.insertText(editor, ` ${imageUrl} `);
  };

  const handleBold = (ev: React.MouseEvent) => {
    wrapWithDelim(ev, "bold", "**");
  };
  const handleItalic = (ev: React.MouseEvent) => wrapWithDelim(ev, "italic", "*");

  const handleHeading = (ev: React.MouseEvent) => startWithDelim(ev, "heading", "#", false);
  const handleBlockquote = (ev: React.MouseEvent) => startWithDelim(ev, "blockquote", ">", true);
  const handleUnorderedList = (ev: React.MouseEvent) => startWithDelim(ev, "unordered list", "-", false);

  const handleImageDrag = (ev: React.MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (ev.type === "dragenter" || ev.type === "dragover") {
      //setDragActive(true);
      setState(prev => ({ ...prev, dragActive: true }));
    } else if (ev.type === "dragleave") {
      //setDragActive(false);
      setState(prev => ({ ...prev, dragActive: false }));
    } else {
      console.log("handleImageDrag drag event", ev.type);
    }
  };

  const handleDrop = function (ev: React.DragEvent) {
    ev.preventDefault();
    ev.stopPropagation();
    let newState = { ...state };
    // setDragActive(false);
    newState.dragActive = false;
    if (ev.dataTransfer?.files && ev.dataTransfer.files[0]) {
      //setFileDropped(ev.dataTransfer.files[0]);
      newState.fileDropped = ev.dataTransfer.files as File;
    }
    setState(prev => ({ ...prev, ...newState }));
  };

  const handleChange = function (ev: React.FormEventHandler<HTMLDivElement>) {
    ev.preventDefault();
    if (ev.target.files && ev.target.files[0]) {
      setState(prev => ({
        ...prev,
        fileDropped: ev.target.files as File
      }));
    }
  };

  const handleImage = (ev: React.MouseEvent) => {
    ev.stopPropagation();
    setState(prev => ({ ...prev, open: true }));
  };

  const dimensions = {};
  // TODO: notifications for not writing or dropping anything
  const handleImageSubmit = async (file?: React.MouseEventHandler<HTMLButtonElement>) => {
    const newState = { ...state, fileUploading: true };

    const prepareFile = fileDropped || (file as unknown as File);
    const preparedFiles = Array.from(prepareFile);

    setState({ ...newState });
    //if (state.fileUploading == true) return;
    console.log({ prepareFile, uploading: state.fileUploading });

    for (const theFile of preparedFiles) {
      newState.fileUploading = true;
      setState(prev => ({ ...prev, newState }));
      await uploadImage(theFile, account)
        .then(({ url }: ImgurData) => {
          insertImage(url as string);
          newState.fileUploading = false;
          setState(prev => ({ ...prev, newState }));
        })
        .catch(error => {
          console.log(error);
          newState.fileUploading = false;
          setState(prev => ({ ...prev, newState }));
        })
        .finally(() => {
          setState(prev => ({ ...prev, fileUploading: false }));
          setTimeout(() => {
            newState.open = false;
            newState.fileDropped = null;
            newState.imageUrl = false;
            newState.fileUploading = false;
            setState(prev => ({ ...prev, ...newState }));
          }, 500);
        });
      if (imageUrl !== false) {
        insertImage(imageUrl);
        newState.open = false;
        newState.fileDropped = null;
        newState.imageUrl = false;
        newState.fileUploading = false;
        setState({ ...state, ...newState });
        return;
      }
    }
  };

  useEffect(() => {
    if (!open) {
      setState(prev => ({ ...prev, fileDropped: null }));
    }
  }, [open]);

  const checkIfContainsLink = (value: string): boolean => {
    const linkPattern = new RegExp(/(\bhttps?:\/\/\S+)/g);
    const imagePattern = new RegExp(/(https?:\/\/.*\.(?:jpg|jpeg|png|gif|bmp|webp|svg|tiff|ico))/i);

    return !!(
      // !value?.includes("inleo.io") &&
      (
        value.match(linkPattern) &&
        !value.match(imagePattern) &&
        // !isPostLink(value) &&
        !isThreadLink(value)
      )
    );
  };

  const handlePaste = async (pasteEvent: React.ClipboardEvent) => {
    const file = pasteEvent?.clipboardData?.files;
    console.log(file);
    if (file?.[0]?.type?.split("/")[0] === "image") {
      handleImageSubmit(file);
    }
  };

  const initialEditorContent = initialContent
    ? [
        {
          type: "paragraph",
          children: [{ text: initialContent }]
        }
      ]
    : initialValue;

  function getCaretCoordinates() {
    var x = 0,
      y = 0;
    var sel, range, rect;

    if (window.getSelection) {
      sel = window.getSelection();
      if (sel!.rangeCount) {
        range = sel!.getRangeAt(0).cloneRange();
        if (range.getClientRects) {
          range.collapse(true);
          rect = range.getClientRects()[0];
          if (rect) {
            x = rect.left;
            y = rect.top;
          }
        }
        // Fall back to using offset coordinates if getClientRects is not supported
        if (x === 0 && y === 0) {
          range.collapse(true);
          var offset = range.startOffset;
          var node = range.startContainer;
          var rangeRect = node!.getBoundingClientRect();
          x = rangeRect.left + offset * 10;
          y = rangeRect.top;
        }
      }
    }
    return { x: x, y: y };
  }

  const getCursorLocation = (editor: BaseEditor & ReactEditor & HistoryEditor) => {
    const { selection } = editor;

    if (selection) {
      const [start] = Range.edges(selection);
      const path = start.path;
      const offset = start.offset;

      return { path, offset };
    }

    return null;
  };

  const getCharactersBehindCursor = (editor, cursorLocation, numCharacters) => {
    const { path, offset } = cursorLocation;
    const startOffset = Math.max(0, offset - numCharacters);
    const start = { path, offset: startOffset };
    const end = { path, offset };
    const range = Editor.range(editor, start, end);
    const characters = Editor.string(editor, range);

    return characters;
  };

  const editorUpdate = async changes => {
    handleSaveThreadDraft();

    let links: Set<string> = new Set([]);

    changes.map(contents => {
      return contents.children.map(content => {
        if (checkIfContainsLink(content.text)) {
          links.add(content.text as string);
        }
      });
    });

    if (links.size > 0) {
      setState(prev => ({ ...prev, containsLink: true }));
    } else {
      setState(prev => ({ ...prev, containsLink: false }));
    }

    const cursorLocation = getCursorLocation(editor);
    if (cursorLocation) {
      const charactersBehindCursor = getCharactersBehindCursor(editor, cursorLocation, 16);
      if (!charactersBehindCursor) return;
      const charactersBehindCursorParsed = charactersBehindCursor.split("@").at(1)?.split(" ");
      if (charactersBehindCursorParsed === undefined) return;
      const [startOfMention, endOfMention] = charactersBehindCursorParsed as any;
      const isMention = startOfMention !== "" && endOfMention === undefined;
      if (isMention) {
        const lookupResults = await queryAccountLookup(startOfMention);
        setState(prev => ({
          ...prev,
          mentionSuggestionActive: true,
          caretCoordinates: getCaretCoordinates() as any,
          mentionWritten: startOfMention,
          ...(lookupResults !== [] ? { accountLookupResults: lookupResults } : {})
        }));
      } else {
        setState(prev => ({
          ...prev,
          mentionSuggestionActive: false,
          caretCoordinates: false
        }));
      }
    }
  };

  const handleSaveThreadDraft = () => {
    if (!mainThreadEditor || !editor?.children || typeof window === "undefined") return;

    const content = editor.children
      .map(n => Node.string(n))
      .join("\n")
      ?.trimStart();

    window.localStorage.setItem("threads-children", content);
  };

  const onClose = () => {
    setState(prev => ({
      ...prev,
      mentionSuggestionActive: false,
      caretCoordinates: false,
      mentionWritten: false,
      accountLookupResults: []
    }));
  };

  const onMentionClick = (accountName: string) => {
    const textToBeInserted = accountName?.slice(mentionWritten.split("")?.length, accountName?.length);
    editor.insertText(`${textToBeInserted} `);
    onClose();
  };

  const content = editor.children.map(n => Node.string(n)).join("\n");

  useEffect(() => {
    setState(prev => ({ ...prev, editorMounted: true }));
  }, []);

  useEffect(() => {
    if (typeof window === "undefined" || !editorMounted || !mainThreadEditor) return;

    const draft = window.localStorage.getItem("threads-children") as string;
    if (!draft) return;

    const node = [
      {
        type: "paragraph",
        children: [
          {
            text: draft
          }
        ]
      }
    ];

    if (initialEditorContent) {
      Transforms.removeNodes(editor, {
        at: [0]
      });
    }

    Transforms.insertNodes(editor, node);
  }, [editorMounted]);

  useEffect(() => {
    // NEED UPDATES
    const handleKeyDown = (ev: KeyboardEvent) => {
      if (ev.key === "Enter" && ev.ctrlKey === true) {
        // handlePost(
        //   editor.children.map(n => Node.string(n)).join("\n"),
        //   pollOptions
        // );
        // const children = [...editor.children];

        // children.forEach(node =>
        //   editor.apply({ type: "remove_node", path: [0], node })
        // );

        // Transforms.insertNodes(editor, initialValue);
        if (threadSubmitRef.current) {
          threadSubmitRef.current.click();
        }
        ev.preventDefault();
      }
      if (ev.key === "b" && (ev.ctrlKey === true || ev.metaKey === true)) {
        handleBold(ev as any);
        ev.preventDefault();
      }
      if (ev.key === "h" && (ev.ctrlKey === true || ev.metaKey === true)) {
        handleHeading(ev as any);
        ev.preventDefault();
      }
      if (ev.key === "q" && (ev.ctrlKey === true || ev.metaKey === true)) {
        handleBlockquote(ev as any);
        ev.preventDefault();
      }
      if (ev.key === "u" && (ev.ctrlKey === true || ev.metaKey === true)) {
        handleUnorderedList(ev as any);
        ev.preventDefault();
      }
      if (ev.key === "i" && (ev.ctrlKey === true || ev.metaKey === true)) {
        handleItalic(ev as any);
        ev.preventDefault();
      }
      if (ev.key === "Escape") {
        onClose();
      }
      if (ev.key === "Enter" || ev.key === "Tab") {
        if (mentionSuggestionActive) {
          ev.preventDefault();
          ev.stopImmediatePropagation();
          ev.stopPropagation();
          onMentionClick(accountLookupResults[0]);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [mentionSuggestionActive, accountLookupResults, mentionWritten, editor]);

  /*bu yarrağım yer useEffect(() => {
    const handleScroll = () => {
      onClose();
    };

    document.addEventListener("scroll", handleScroll);

    return () => document.removeEventListener("scroll", handleScroll);
  }, []);*/

  React.useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        ReactEditor.focus(editor);
      }, 1500);
    }
    ReactEditor.focus(editor);
  }, [autoFocus, editor]);

  const insertPoll = (poll: PollOptions) => {
    enforceSelection();

    // setPollOptions(poll);
    // setPollCreated(true);
    setState(prev => ({ ...prev, pollOptions: poll, pollCreated: true }));
  };

  const removePoll = () => {
    // setPollOptions({ 1: "", 2: "" });
    // setPollCreated(false);
    setState(prev => ({
      ...prev,
      pollOptions: { 1: "", 2: "" },
      pollCreated: false
    }));
  };

  useEffect(() => {
    // Auto focus fix
    if (autoFocus) Transforms.select(editor, Editor.end(editor, []));
  }, [editor, autoFocus]);

  return (
    <ClientOnly>
      {() => (
        <div className={classNames("w-full h-full border-b border-pri dark:border-pri-d", className)}>
          <FloatingPortal>
            {mentionSuggestionActive && accountLookupResults?.some(acc => acc?.includes(mentionWritten)) && (
              <FloatingHTMLOverlay onClick={ev => ev.stopPropagation()} className="z-[1000]">
                <div
                  style={{
                    left: `${caretCoordinates?.x}px`,
                    top: `${caretCoordinates?.y}px`
                  }}
                  ref={mentionViewer}
                  className="relative rounded-xl overflow-hidden mt-8 w-fit min-w-[160px] flex flex-col gap-y-2 border-lg bg-gray-100 dark:bg-zinc-800 border border-pri dark:border-pri-d h-fit -translate-x-1/3"
                >
                  {accountLookupResults?.slice(0, 5).map((username, i) => {
                    if (accountLookupResults[0]?.split(mentionWritten)[0] !== "") {
                      return null;
                    }
                    return (
                      <div
                        key={username}
                        onClick={() => onMentionClick(username)}
                        className="flex flex-row w-full p-1 gap-2 px-4 pl-3 py-2.5 hover:bg-black/10 dark:hover:bg-white/10 items-center align-center transition-colors duration-150 cursor-pointer"
                      >
                        <SmallAvatar8 author={username} />
                        <span className="font-medium text-sm">{username}</span>
                      </div>
                    );
                  })}
                </div>
              </FloatingHTMLOverlay>
            )}
          </FloatingPortal>

          <div className="flex h-full flex-col w-full">
            {state.fileUploading && <FileUploadSpinLoader />}
            {containsLink && (
              <WarningMessage
                message={`Your Thread contains a link. If you'd like to share a link,
                please post the top-level thread and then make a reply
                thread to it with the link.`}
              />
            )}
            <Slate editor={editor} value={initialEditorContent as unknown as any} onChange={editorUpdate}>
              <div
                className={classNames(
                  "flex items-start gap-x-4 py-4 px-4 break-all overflow-hidden relative",
                  className
                )}
              >
                <div
                  onClick={() => document.getElementById("thread-markdown")?.focus()}
                  className="flex w-12 h-12 rounded-full overflow-hidden"
                >
                  <SmallAvatar12 author={activeAccount?.name} />
                </div>

                <div
                  className="flex flex-1 p-2"
                  id="thread-markdown"
                  style={{ wordBreak: "break-word" }}
                  onClick={() => document.getElementById("thread-markdown")?.focus()}
                >
                  <Editable
                    onPaste={handlePaste}
                    onClick={() => document.getElementById("thread-markdown")?.focus()}
                    className="markdown-editor flex-1 shrink-0 basis-32 relative"
                    style={{
                      wordBreak: "break-word"
                    }}
                    autoFocus={autoFocus}
                    aria-label={placeholder}
                    placeholder={placeholder}
                    renderPlaceholder={placeholder => (
                      <p
                        {...placeholder.attributes}
                        className="text-base font-medium"
                        style={{
                          ...placeholder.attributes.style,
                          wordBreak: "break-word",
                          userSelect: "none",
                          pointerEvents: "none"
                        }}
                      >
                        {placeholder.children}
                      </p>
                    )}
                  />

                  {typeof encryption !== "undefined" && typeof setEncryption === "function" ? (
                    <EncryptionToggle encryption={encryption} setEncryption={setEncryption} />
                  ) : null}
                </div>
              </div>

              {!premiumState.is_premium && content.length > 240 && (
                <div className="flex flex-1 px-5 py-3">
                  <div className="flex flex-1 py-2.5 px-4 gap-x-3 bg-acc/[.15] text-pri dark:text-pri-d text-sm font-medium rounded-xl">
                    Only the first 240 characters will be visible on the timeline.
                    <button
                      type="button"
                      onClick={() => setState(prev => ({ ...prev, showInfo: false }))}
                      className="flex w-5 h-5 rounded-full justify-center items-center ml-auto hover:opacity-70 transition-opacity duration-150"
                    >
                      <FontAwesomeIcon icon={faX} size="sm" />
                    </button>
                  </div>
                </div>
              )}

              <EditorFooter
                type={type}
                togglePreview={togglePreview}
                setTogglePreview={setTogglePreview}
                handlePost={handlePost}
                encrypted={encryption}
                dimensions={dimensions}
                pollsOpen={pollsOpen}
                setPollOptions={setPollOptions}
                pollOptions={pollOptions}
                threadSubmitRef={threadSubmitRef}
                isPollCreated={pollCreated}
                setIsPollCreated={setPollCreated}
                removePoll={removePoll}
                handleBold={handleBold}
                handleItalic={handleItalic}
                handleHeading={handleHeading}
                handleImage={handleImage}
                handleBlockquote={handleBlockquote}
                handleUnorderedList={handleUnorderedList}
                setPollsOpen={setPollsOpen}
                insertImage={insertImage}
              />
            </Slate>
          </div>

          <PollPortal
            pollsOpen={pollsOpen}
            setPollsOpen={setPollsOpen}
            setPollOptions={setPollOptions}
            pollOptions={pollOptions}
            isPollCreated={pollCreated}
            handlePoll={insertPoll}
          />
          <FloatingPortal>
            {open && (
              <ImageSelector
                dragActive={dragActive}
                setOpen={setOpen}
                fileDropped={fileDropped}
                fileUploadLogo={fileUploadLogo}
                fileUploading={fileUploading}
                handleChange={handleChange}
                handleDrop={handleDrop}
                handleImageDrag={handleImageDrag}
                handleImageSubmit={handleImageSubmit}
                imageInputRef={imageInputRef}
                setImageUrl={setImageUrl}
                imageUrl={imageUrl}
              />
            )}
          </FloatingPortal>
        </div>
      )}
    </ClientOnly>
  );
};

MarkdownEditor.displayName = "MarkdownEditor";
export default MarkdownEditor;

function EncryptionToggle({
  encryption,
  setEncryption
}: {
  encryption: boolean;
  setEncryption: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const t = loadTranslator(useRouteLoaderData("root").translations)
  const [open, setOpen] = React.useState(false);
  const subscriptions_enabled = useAppStore(store => store.settings.subscriptions_enabled);

  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: open => setOpen(open),
    middleware: [offset(10)],
    placement: "bottom-end",
    strategy: "absolute"
  });

  const click = useClick(context, {
    event: "click"
  });

  const role = useRole(context, { role: "menu" });
  const dismiss = useDismiss(context, { ancestorScroll: true });

  const { getReferenceProps, getFloatingProps } = useInteractions([click, role, dismiss]);

  if (!subscriptions_enabled) return null;
  return (
    <div ref={reference} {...getReferenceProps} onClick={() => setOpen(current => !current)} className="h-fit">
      <button
        type="button"
        className="flex justify-center items-center py-1 px-2.5 rounded-full gap-x-1.5 border border-pri dark:border-pri-d bg-pri-d/[0.075] dark:bg-pri/[0.075] text-pri/70 dark:text-pri-d/70 text-sm"
      >
        <span>{encryption ? t("private") : t("public")}</span>
        <FontAwesomeIcon icon={faChevronDown} size="xs" fixedWidth />
      </button>
      <FloatingPortal>
        <AnimatePresence>
          {open && (
            <motion.div
              initial={{ y: -6, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -6, opacity: 0 }}
              transition={{ duration: 0.15 }}
              className="flex flex-col justify-start items-start bg-pri dark:bg-pri-d border border-pri dark:border-pri-d rounded-xl p-1.5 text-sm z-[1000] overflow-hidden drop-shadow-md min-w-[200px] shadow-[0_0_12px_3px_rgb(255_255_255_/_17%)]"
              ref={floating}
              style={{ position: strategy, top: y ?? 0, left: x ?? 0 }}
              {...getFloatingProps()}
              tabIndex={-1}
            >
              <button
                type="button"
                className={cn(
                  "flex items-center w-full gap-x-2 py-3 px-3 text-pri/70 dark:text-pri-d/70 rounded-lg transition-colors duration-150 hover:bg-pri-d/10 dark:hover:bg-pri/10",
                  {
                    "text-pri dark:text-pri-d": encryption
                  }
                )}
                onClick={() => setEncryption(true)}
              >
                <FontAwesomeIcon icon={faCrown} size="sm" fixedWidth />
                {t("subscribers-only")}
              </button>
              <button
                type="button"
                className={cn(
                  "flex items-center w-full gap-x-2 py-3 px-3 text-pri/70 dark:text-pri-d/70 rounded-lg transition-colors duration-150 hover:bg-pri-d/10 dark:hover:bg-pri/10",
                  {
                    "text-pri dark:text-pri-d": !encryption
                  }
                )}
                onClick={() => setEncryption(false)}
              >
                <FontAwesomeIcon icon={faLockOpen} size="sm" fixedWidth />
                {t("public")}
              </button>
            </motion.div>
          )}
        </AnimatePresence>
      </FloatingPortal>
    </div>
  );
}
