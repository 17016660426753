import React, { useEffect, useState } from "react";
import { FloatingPortal } from "@floating-ui/react";
import { LazyMotion, m } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation, faSpinner, faUserGroup, faVideo, faX } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { useFileUpload } from "~/hooks/3speakHooks";
import { getVideoDuration } from "~/utils/single/getVideoDuration";
import { useAppStore } from "~/store";
import loadFeatures from "~/components/framermotion/features";
import { cn } from "~/utils/cn";
import PostingAuthorityModal from "./publish/GivePostingAuthority";
import { extractFirstFrameAsFile } from "~/utils/single/extractFirstFrame";
import { callHiveClient } from "~/utils/hive";
import { loadTranslator } from "~/utils/loadTranslator";
import { useRouteLoaderData } from "@remix-run/react";

// const loadFeatures = () =>
//   import("~/components/framermotion/features").then(res => res.default);

interface UploadShortsProps {
  visibility: boolean;
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  onUpload: (file: File) => void;
  insertText: (s: string) => void;
}

export default function UploadShorts(props: UploadShortsProps) {
  const [{ activeAccount, keys }, isDarkMode, isAuthoritySet, setPlaceholderVideo] = useAppStore(store => [
    store.account,
    store.settings.dark,
    store.markdown.authoritySet,
    store.markdown.setPlaceHolderVideo
  ]);

  const t = loadTranslator(useRouteLoaderData("root").translations);

  const modalInnerRef = React.useRef(null);
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const [sent, setSent] = useState(false);

  const [uploadedVideo, setUploadedVideo] = React.useState<File | null>(null);

  const [encodingProgress, setEncodingProgess] = React.useState<number | undefined>(undefined);
  const [publishingProgress, setPublishingProgress] = React.useState<number | undefined>(undefined);

  const [authorityVisibility, setAuthorityVisibility] = React.useState<boolean>(false);

  const {
    upload: uploadThumbnail,
    uploadResponse: thumbnailUploadResponse,
    progress: thumbnailProgress
  } = useFileUpload();
  const {
    upload: uploadVideoTo,
    uploadResponse: videoUploadResponse,
    progress: uploadProgress,
    error: videoUploadError
  } = useFileUpload();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [permlink, setPermlink] = useState(null);
  const [videoToBeUploaded, setVideoToBeUploaded] = useState(null);
  const [currentAccount, setCurrentAccount] = useState();
  const [duration, setDuration] = useState(0);

  const hideModal = () => {
    props.setVisibility(false);
    setUploadedVideo(null);
    setPublishingProgress(undefined);
    setEncodingProgess(undefined);
    setTitle("");
    setDescription("");
    setPermlink(null);
    setVideoToBeUploaded(null);
  };
  function handleUploadVideo(event: React.ChangeEvent<HTMLInputElement>) {
    const video = event.target.files?.[0];
    setVideoToBeUploaded(video);

    if (!video) {
      return toast("Please upload a valid video.", { type: "error" });
    }

    setUploadedVideo(video);
  }

  React.useEffect(() => {
    if (videoToBeUploaded) {
      void (async function () {
        const duration = await getVideoDuration(videoToBeUploaded);
        if (duration > 60) {
          setVideoToBeUploaded(null);
          setUploadedVideo(null);
          return toast("Uploaded video should be short than 60 seconds.", {
            type: "error",
            theme: isDarkMode ? "dark" : "light"
          });
        }
      })();
    }
  }, [videoToBeUploaded]);

  async function uploadVideo() {
    if (!uploadShortAuthority) {
      return toast("Please give authority to @leofinance first.", {
        type: "error"
      });
    }

    if (videoToBeUploaded) {
      const firstFrame = await extractFirstFrameAsFile(videoToBeUploaded);
      uploadThumbnail(firstFrame as File);
      uploadVideoTo(videoToBeUploaded);
    }

    if (!uploadedVideo) {
      return toast("Please upload a video.", { type: "error" });
    }

    props.onUpload(uploadedVideo);
  }

  useEffect(() => {
    if (videoUploadError !== null) {
      toast("There has been an error while video is getting uploaded to 3Speak please retry.", {
        type: "info",
        position: "top-center",
        theme: isDarkMode ? "dark" : "light",
        autoClose: 5_000
      });
    }
  }, [videoUploadError]);

  useEffect(() => {
    if (sent) return;
    if (props.visibility === false) return;
    if (!permlink) return;

    void (async function () {
      const videoLink = `https://3speak.tv/watch?v=${permlink.author}/${permlink.permlink}`;
      setPlaceholderVideo(videoLink, URL.createObjectURL(videoToBeUploaded), permlink, "");

      const authorization = {
        username: activeAccount.name,
        signature: keys.signature,
        message: JSON.stringify({ login: activeAccount.name }),
        body: description == "" || description == " " ? `${videoLink}` : `${description}\n   ${videoLink}`,
        permlink: permlink.permlink
      };

      const verifyResult = await (
        await fetch("https://inleo.io/shorts-api/shorts/verify", {
          method: "POST",
          body: JSON.stringify(authorization)
        })
      ).json();

      setSent(true);
      console.log(verifyResult);

      console.log(authorization);

      toast(
        "Your Short has been successfully uploaded to the queue. It will be live on-chain within about 10 minutes.",
        {
          type: "info",
          position: "top-center",
          theme: isDarkMode ? "dark" : "light",
          autoClose: 5_000
        }
      );

      hideModal();
      /*props.insertText(
        `${description}
        \nhttps://3speak.tv/watch?v=${permlink.author}/${permlink.permlink}`
        );
        const result = await (
          await fetch(
            `https://inleo.io/shorts-api/shorts/encoding/${permlink.permlink}`
            )
            ).json();
            
            if (result.status === "encoding_ipfs") {
              setEncodingProgess(50);
            }
            
            if (result.status === "publish_manual") {
              props.insertText(
                `https://3speak.tv/watch?v=${permlink.author}/${permlink.permlink}`
                );
                setEncodingProgess(100);*/
    })();
  }, [permlink, props.visibility, description, keys, activeAccount, sent]);

  const submitText = React.useMemo(() => {
    if (uploadProgress > 0 && uploadProgress < 100) {
      return "Uploading";
    }

    if (encodingProgress >= 0 && encodingProgress < 100) {
      return "Encoding";
    }

    if (publishingProgress >= 0 && publishingProgress < 100) {
      return "Publishing";
    }

    return "Upload";
  }, [uploadProgress, encodingProgress, publishingProgress]);

  useEffect(() => {
    void (async function () {
      if (!videoUploadResponse) return;

      const shortsUploadPayload = {
        url: videoUploadResponse.url.replaceAll("https://uploads.3speak.tv/files/", ""),
        duration: await getVideoDuration(videoToBeUploaded),
        size: videoToBeUploaded.size,
        oFilename: videoToBeUploaded.name,
        user: activeAccount?.name,
        thumbnail: thumbnailUploadResponse.url.replaceAll("https://uploads.3speak.tv/files/", ""),
        description,
        title
      };

      let result = await fetch("https://inleo.io/shorts-api/shorts/upload", {
        method: "POST",
        body: JSON.stringify(shortsUploadPayload)
      });

      setPermlink({
        ...(await result.json())
      });
    })();
  }, [videoUploadResponse, videoToBeUploaded, activeAccount, thumbnailUploadResponse, title, description]);

  useEffect(() => {
    void (async function () {
      const accounts_array = (await callHiveClient("condenser_api", "get_accounts", [[activeAccount?.name]]))[0];

      setCurrentAccount(accounts_array);
    })();
  }, []);

  const inProgress = uploadProgress > 0 && uploadProgress < 100;

  const uploadShortAuthority =
    isAuthoritySet === true || !!currentAccount?.posting?.account_auths?.find(a => a[0] === "leofinance");

  const videoSrc = React.useMemo(() => {
    if (!uploadedVideo) return;

    return URL.createObjectURL(uploadedVideo);
  }, [uploadedVideo]);

  return (
    <FloatingPortal>
      <LazyMotion features={loadFeatures}>
        {props.visibility && !authorityVisibility && (
          <m.div
            initial={{ opacity: 0, scale: 0.97 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.97 }}
            className="fixed inset-0 flex justify-center items-center bg-black/40 z-[10001]"
          >
            <div
              ref={modalInnerRef}
              className="relative flex flex-col min-w-[360px] sm:min-w-[520px] py-5 px-6 gap-y-9 rounded-xl drop-shadow-lg bg-pri dark:bg-pri-d border border-pri dark:border-pri-d shadow-[0_0_12px_3px_rgb(255_255_255_/_15%)]"
            >
              <header className="flex flex-1 items-center justify-between gap-x-8">
                <h1 className="text-2xl font-bold">{t("upload-short")}</h1>
                <button
                  type="button"
                  className="flex items-center justify-center w-8 h-8 rounded-full border border-pri/50 dark:border-pri-d/50 text-pri/80 dark:text-pri-d/80 hover:border-pri dark:hover-border-pri-d hover:text-pri dark:hover:text-pri-d hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075] transition-colors duration-150 z-20"
                  onClick={() => hideModal()}
                >
                  <FontAwesomeIcon icon={faX} size="xs" />
                </button>
              </header>

              <main className="flex flex-1 flex-col w-full gap-y-6">
                <div className="flex flex-1 flex-col gap-y-3">
                  {videoSrc ? (
                    <video
                      ref={videoRef}
                      src={videoSrc}
                      className="border border-pri dark:border-pri-d rounded-xl overflow-hidden bg-black w-full max-w-[470px] aspect-video"
                      controls
                    />
                  ) : (
                    <label
                      htmlFor="uploadShort"
                      className="relative flex flex-col justify-center items-center gap-y-3.5 w-full h-52 rounded-xl border-2 border-dashed border-pri dark:border-pri-d transition-all duration-150 hover:bg-pri-d/5 dark:hover:bg-pri/5 cursor-pointer"
                    >
                      <FontAwesomeIcon icon={faVideo} size="3x" className="text-pri/30 dark:text-pri-d/30" />
                      <span className="flex flex-col justify-center items-center text-center">
                        <span className="text-sm font-medium text-pri/80 dark:text-pri-d/80">
                          {t("click-to-select-video")}
                        </span>

                        <span className="text-sm text-pri/60 dark:text-pri-d/60">
                          (.mp4, .mov, .flv, .webm, .mkv) are supported.
                        </span>
                      </span>
                    </label>
                  )}

                  <input
                    id="uploadShort"
                    type="file"
                    accept="video/*"
                    onChange={event => handleUploadVideo(event)}
                    hidden
                  />
                </div>

                <div className="flex flex-col gap-y-4">
                  <div className="flex flex-col gap-y-1.5">
                    <label className="text-sm font-medium text-pri/60 dark:text-pri-d/60">{t("title")}</label>
                    <input
                      type="text"
                      value={title}
                      onChange={e => setTitle(e.target.value)}
                      className="flex py-2.5 px-4 rounded-xl border border-pri dark:border-pri-d bg-pri-d/5 dark:bg-pri/5 outline-none text-sm font-medium transition-all duration-150 focus:border-bg-d dark:focus:border-bg placehoder:text-pri/50 dark:placeholder:text-pri-d/30"
                      placeholder={t("title")}
                    />
                  </div>

                  <div className="flex flex-col gap-y-1.5">
                    <label className="text-sm font-medium text-pri/60 dark:text-pri-d/60">{t("description")}</label>
                    <textarea
                      onChange={e => setDescription(e.target.value)}
                      maxLength={240}
                      value={description}
                      className="flex py-2.5 px-4 rounded-xl border border-pri dark:border-pri-d bg-pri-d/5 dark:bg-pri/5 outline-none text-sm font-medium resize-none transition-all duration-150 focus:border-bg-d dark:focus:border-bg placehoder:text-pri/50 dark:placeholder:text-pri-d/30"
                      placeholder={t("description")}
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-y-2.5">
                  <span className="text-sm font-medium">{t("upload-progress")}</span>

                  <div className="flex items-start justify-center min-h-[23px] gap-x-1">
                    <div className="flex flex-1 flex-col gap-y-1 justify-end items-end">
                      <div className="relative flex w-full h-0.5 rounded-full bg-gray-200 dark:bg-zinc-700">
                        <span
                          className="absolute w-full h-full bg-blue-500 origin-left transition-transform duration-75 ease-linear"
                          style={{
                            transform: `scaleX(${uploadProgress || 0}%)`
                          }}
                        />
                      </div>

                      {uploadProgress ? (
                        <span className="text-xs text-pri/50 dark:text-pri-d/50">
                          {t("uploading")} {uploadProgress.toFixed()}%
                        </span>
                      ) : null}
                    </div>

                    <div className="flex flex-1 flex-col gap-y-1 justify-end items-end">
                      <div className="relative flex w-full h-0.5 rounded-full bg-gray-200 dark:bg-zinc-700">
                        <span
                          className={cn("absolute w-full h-full bg-transparent origin-center transition-all", {
                            "animate-pulse bg-purple-500": uploadProgress >= 100
                          })}
                        />
                      </div>

                      {uploadProgress >= 100 ? (
                        <span className="text-xs text-pri/50 dark:text-pri-d/50">{t("encoding")}</span>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="flex items-center justify-end gap-x-1">
                  <button
                    type="button"
                    aria-label="Cancel"
                    className="flex items-center w-fit py-2.5 px-5 rounded-full border border-pri dark:border-pri-d text-sm font-medium hover:bg-pri-d/[0.075] dark:hover:bg-pri/[0.075] transition-colors duration-150"
                    onClick={() => hideModal()}
                  >
                    {t("cancel")}
                  </button>

                  <button
                    type="button"
                    aria-label="Delegate"
                    className="flex items-center justify-center w-fit min-h-[42px] min-w-[108px] py-2 px-6 gap-x-2 rounded-full bg-pri-d dark:bg-pri text-pri-d dark:text-pri text-sm font-medium hover:opacity-80 transition-opacity duration-150 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:opacity-50"
                    onClick={() => uploadVideo()}
                    disabled={duration > 60 || videoUploadError ? false : inProgress || uploadProgress >= 100}
                  >
                    {inProgress || uploadProgress >= 100 ? (
                      <FontAwesomeIcon icon={faSpinner} size="sm" className="animate-spin" />
                    ) : null}
                    {videoUploadError
                      ? t("retry")
                      : inProgress
                      ? t("uploading")
                      : uploadProgress >= 100
                      ? t("encoding")
                      : t("upload")}
                  </button>
                </div>
              </main>

              {!uploadShortAuthority ? (
                <div className="absolute inset-0 size-full flex flex-col justify-center items-center gap-y-4 text-red-500 text-center backdrop-blur-sm bg-black/50 rounded-xl z-10">
                  <span className="flex items-center gap-x-3 p-3 max-w-[400px] rounded-xl bg-red-500/15 text-sm text-start font-medium">
                    <FontAwesomeIcon icon={faCircleExclamation} size="sm" fixedWidth />
                    You need to give authority to @leofinance before uploading short.
                  </span>
                  <button
                    type="button"
                    className="flex justify-center items-center py-2.5 px-5 gap-x-2 rounded-full text-sm font-medium bg-pri-d dark:bg-pri text-pri-d dark:text-pri hover:opacity-80 transition-opacity duration-150"
                    onClick={() => setAuthorityVisibility(true)}
                  >
                    <FontAwesomeIcon icon={faUserGroup} size="sm" fixedWidth />
                    Give Authority
                  </button>
                </div>
              ) : null}
            </div>
          </m.div>
        )}

        <PostingAuthorityModal
          authority="leofinance"
          hideModal={hideModal}
          visibility={authorityVisibility}
          setVisibility={setAuthorityVisibility}
        />
      </LazyMotion>
    </FloatingPortal>
  );
}
