import { FloatingPortal } from "@floating-ui/react";
import {
  faCopy,
  faArrowDown,
  faRepeat,
  faFileLines,
  faEdit,
  faTrash,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { Suspense, useState } from "react";
import { toast } from "react-toastify";
import useOnClickOutside from "~/hooks/useClickOutside";
import { useMediaQuery } from "~/hooks/useMediaQuery";
import { useAppStore } from "~/store";
import { deleteComment } from "~/utils/transactions";
import { FloatingHTMLOverlay } from "../FloatingHTMLOverlay";
import {
  SmallAvatar10,
  SmallAvatar12,
  SmallAvatar16
} from "../format/SmallAvatar";
import { PremiumOnlyBadge } from "../Premium";
import { copyWithRef } from "~/utils/ref";
import { cn } from "~/utils/cn";
import { useScotContent } from "~/hooks/contentHooks";
import useScrollDirection from "~/hooks/useScrollDirection";
import { useRouteLoaderData } from "@remix-run/react";
import { loadTranslator } from "~/utils/loadTranslator";

const ThreadOptions = React.forwardRef(
  (
    {
      threadContent,
      visible,
      setVisible,
      setDownvote,
      setRethread,
      setEditThread
    },
    ref
  ) => {
    const t = loadTranslator(useRouteLoaderData("root").translations)
    const activeAccount = useAppStore(store => store.account.activeAccount);
    const isDarkMode = useAppStore(store => store.settings.dark);
    const setLists = useAppStore(store => store.utilities.setLists);

    const premiumState = React.useMemo(() => {
      if (!activeAccount) return;

      return activeAccount.premium?.is_premium ?? false;
    }, [activeAccount]);

    const isMobile = useMediaQuery("(max-width: 720px)");
    const direction = useScrollDirection(200);

    const encrypted = threadContent?.json_metadata?.encrypted;

    const [votesView, setVotesView] = useState(false);

    const author_perm = {
      author: threadContent?.author,
      permlink: threadContent?.permlink
    };

    const [_, scotContent] = useScotContent(
      author_perm,
      threadContent?.active_votes
    );

    useOnClickOutside(ref as React.RefObject<HTMLDivElement>, () =>
      setVisible(false)
    );

    const handleDropdownItemAction = (event, action) => {
      event.preventDefault();
      event.stopPropagation();

      switch (action) {
        case "newtab":
          window.open(
            `https://inleo.io/threads/view/${threadContent.author}/${threadContent.permlink}`,
            "_blank"
          );
          break;
        case "copylink":
          copyWithRef(
            `https://inleo.io/threads/view/${threadContent.author}/${threadContent.permlink}`,
            threadContent.author
          );
          toast("Copied thread link to clipboard", {
            type: "success",
            theme: isDarkMode ? "dark" : "light",
            autoClose: 3000
          });
          break;
        case "tweet":
          if (encrypted) return;
          const bodyForTwitter = threadContent.body
            .replaceAll("#", "")
            .replaceAll("@", "");
          window.open(
            `https://x.com/intent/post?text=${bodyForTwitter}\n&url=https://inleo.io/threads/view/${threadContent.author}/${threadContent.permlink}`
          );
          break;
        case "list":
          setLists({ modal: true, author: threadContent?.author });
          break;
        case "downvote":
          setDownvote(true);
          break;
        case "rethread":
          if (encrypted) return;
          setRethread(true);
          break;
        case "edit":
          if (!premiumState) {
            return toast("You need to be premium user to use this feature.", {
              type: "warning",
              theme: isDarkMode ? "dark" : "light",
              autoClose: 3000
            });
          }
          setEditThread(true);
          break;
        case "delete":
          deleteComment({
            author: threadContent?.author || "",
            permlink: threadContent?.permlink || ""
          })
            .then(() => {
              toast(
                `Your thread ${threadContent.permlink} is successfully deleted.`,
                {
                  type: "success",
                  theme: isDarkMode ? "dark" : "light",
                  autoClose: 3000
                }
              );
            })
            .catch(() => {
              toast(`Failed to delete thread ${threadContent.permlink}.`, {
                type: "error",
                theme: isDarkMode ? "dark" : "light",
                autoClose: 3000
              });
            });
          break;
        case "cancel":
          setVisible(false);
          break;
        default:
          break;
      }

      setVisible(false);
    };

    const getOptions = () => {
      const options = [
        {
          action: "copylink",
          label: t("copy-link"),
          icon: faCopy,
          disabled: false
        },
        {
          action: "newtab",
          label: t("open-in-new-tab"),
          icon: faCopy,
          disabled: false
        },
        {
          action: "downvote",
          label: t("downvote"),
          icon: faArrowDown,
          disabled: false
        },
        {
          action: "rethread",
          label: t("re-thread"),
          icon: faRepeat,
          disabled: encrypted
        },
        {
          action: "tweet",
          label: t("tweet"),
          icon: "twitter",
          disabled: encrypted
        }
      ];

      if (premiumState) {
        options.push({
          action: "list",
          label: t("add-to-list"),
          icon: faFileLines,
          disabled: false
        });
      }

      if (threadContent.author === activeAccount?.name) {
        options.push({ separator: true });
        options.push({
          action: "edit",
          label: t("edit"),
          icon: faEdit,
          disabled: false,
          extra: <PremiumOnlyBadge className="ml-auto" />
        });
        options.push({
          action: "delete",
          label: t("delete"),
          icon: faTrash,
          disabled: false,
          textClassName: "text-red-500 dark:text-red-500"
        });
      }

      return options;
    };

    const VotersView =
      visible && React.lazy(() => import("../format/VotersView"));

    const OptionItem = ({ option, onClick, className }) => {
      const { action, label, icon, disabled, extra, textClassName } = option;
      return (
        <div
          className={cn(
            "flex items-center w-full p-3 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer",
            className,
            {
              "opacity-50 cursor-not-allowed": disabled,
              [textClassName]: textClassName
            }
          )}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            if (!disabled) {
              onClick(event, action);
            }
          }}
        >
          <span className="flex w-6 mr-2">
            {icon === "twitter" ? (
              <svg
                viewBox="0 0 24 24"
                aria-hidden="true"
                fill="currentColor"
                className="w-[14px] h-[14px]"
              >
                <g>
                  <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
                </g>
              </svg>
            ) : (
              <FontAwesomeIcon icon={icon} />
            )}
          </span>
          {label}
          {extra}
        </div>
      );
    };

    return (
      <div className="relative right-0">
        <FloatingPortal>
          {visible && isMobile && (
            <div
              ref={ref}
              className={cn(
                "fixed h-[100vh] flex flex-col justify-between right-0 left-0 bottom-[48px] z-[10000] translate-y-0 transition-transform duration-150",
                {
                  "translate-y-[48px]": direction === "down"
                }
              )}
            >
              <div className="flex h-fit" />
              <FloatingHTMLOverlay
                onClick={ev => ev.stopPropagation()}
                className={cn(
                  `z-[999] flex flex-col justify-between items-center gap-6 pt-3 px-5 h-fit max-h-[80vh] bottom-0 overflow-hidden border-t border-transparent
                  rounded-3xl rounded-bl-none rounded-br-none pb-2
                font-semibold text-lg relative overflow-y-auto animate-slide-to-bottom
              bg-pri dark:bg-pri-d border border-pri dark:border-pri-d
              drop-shadow-[0_-20px_48px_rgb(0_0_0_/_50%)] shadow-[0px_-6px_12px_rgb(255_255_255_/_12%)]
              `,
                  {
                    hidden: !visible
                  }
                )}
              >
                <span className="text-2xl font-bold py-4 self-start">
                  Thread Options
                </span>

                <div className="flex flex-row items-center self-start gap-x-2.5 px-1">
                  <SmallAvatar12
                    author={threadContent?.author}
                    disableThreadcast
                  />
                  <span>
                    {threadContent?.displayName || threadContent?.author}
                  </span>
                </div>
                <hr className="w-full border-pri/50 dark:border-pri-d/50" />

                {scotContent?.active_votes?.length > 2 ||
                threadContent?.active_votes?.length > 2 ? (
                  <>
                    <button
                      className="flex flex-row items-center gap-x-2 py-1.5 px-4 mx-2 w-fit rounded-full bg-pri dark:bg-pri-d border border-pri dark:border-pri-d text-sm font-semibold shadow-[0_0_12px_3px_rgb(255_255_255_/_15%)] origin-center active:scale-95 transition-transform duration-150"
                      onClick={() => setVotesView(true)}
                    >
                      <div className="flex items-center -space-x-3">
                        {scotContent ? (
                          scotContent.active_votes
                            ?.slice(0, 3)
                            .map((x, index) => (
                              <span
                                key={index}
                                className="flex size-10 bg-gray-200 dark:bg-zinc-800 rounded-full border-2 border-bg dark:border-bg-d overflow-hidden"
                              >
                                <SmallAvatar10
                                  author={x.voter}
                                  className="border-none outline-none"
                                />
                              </span>
                            ))
                        ) : (
                          <>
                            <span className="flex size-8 bg-gray-200 dark:bg-zinc-800 rounded-full border-2 border-bg dark:border-bg-d" />
                            <span className="flex size-8 bg-gray-200 dark:bg-zinc-800 rounded-full border-2 border-bg dark:border-bg-d" />
                            <span className="flex size-8 bg-gray-200 dark:bg-zinc-800 rounded-full border-2 border-bg dark:border-bg-d" />
                          </>
                        )}
                      </div>

                      <span>
                        See {scotContent?.active_votes?.length || 0} voters
                      </span>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        size="sm"
                        fixedWidth
                      />
                    </button>

                    <hr className="w-full border-pri/50 dark:border-pri-d/50" />
                  </>
                ) : null}

                {getOptions().map((option, index) => {
                  if (option.separator) {
                    return (
                      <hr
                        key={index}
                        className="bg-pri-d dark:bg-pri my-2 opacity-10"
                      />
                    );
                  }
                  return (
                    <OptionItem
                      key={index}
                      option={option}
                      onClick={handleDropdownItemAction}
                      className="font-semibold text-lg text-gray-900 dark:text-gray-300"
                    />
                  );
                })}

                <div
                  className="flex items-center w-full p-4 mb-2 justify-center rounded-xl border border-pri dark:border-pri-d hover:bg-pri-d/[0.075] dark:hover:bg-pri/[0.075] transition-colors cursor-pointer"
                  onClick={event => handleDropdownItemAction(event, "cancel")}
                >
                  <span className="self-center font-medium text-pri/80 dark:text-pri-d/80">
                    Cancel
                  </span>
                </div>
              </FloatingHTMLOverlay>
            </div>
          )}
        </FloatingPortal>

        {!isMobile && visible && (
          <div
            className={classNames(
              "absolute top-[100%] w-max min-w-[180px] right-0 mt-2 p-1 flex flex-col rounded-lg bg-pri dark:bg-pri-d border border-pri dark:border-pri-d z-[105] drop-shadow-lg shadow-[0_0_12px_rgb(255_255_255_/_12%)]",
              {
                hidden: !visible
              }
            )}
          >
            {getOptions().map((option, index) => {
              if (option.separator) {
                return (
                  <hr
                    key={index}
                    className="bg-pri-d dark:bg-pri my-2 opacity-10"
                  />
                );
              }
              return (
                <OptionItem
                  key={index}
                  option={option}
                  onClick={handleDropdownItemAction}
                  className="font-semibold text-sm text-gray-900 dark:text-gray-300"
                />
              );
            })}
          </div>
        )}
      </div>
    );
  }
);

ThreadOptions.displayName = "ThreadOptions";
export default ThreadOptions;
